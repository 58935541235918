import React from "react";
import imgBenefits from "../images/Benefits.png";
import { FaCheck } from "react-icons/fa";

function Benefits() {
	const benefits = ["Improve focus and presence", "Meaningful connections with others", "Encourages mindfulness", "Anxiety and stress management", "Organisational care and support"];
  return (
		<div className="lg:flex lg:items-center lg:space-x-12 xl:space-x-24">
			<div className="lg:w-1/2 space-y-8">
				<h2>Mental Health Kit Benefits</h2>
				<p>The kits are designed for organisation leaders, educational institutions and business managers to support their teams, employees, clients or students.</p>
				<ul className="space-y-2 lg:space-y-2 xl:space-y-4">
					{benefits.map((benefit) => (
						<li key={benefit} className="md:bg-teal-light md:py-2 md:px-4 md:rounded-full md:text-lg xl:py-3 xl:px-8 xl:text-lg flex"><FaCheck className="inline text-teal mr-2 lg:mr-4 mt-1 flex-none" />{benefit}</li>
					))}
				</ul>
			</div>
			<div className="lg:w-1/2 md:hidden lg:block">
				<figure>
					<img className="m-auto" src={imgBenefits} alt="Three illustrations depict activities such as a couple cuddling, an individual listening to music and an individual reading" />
				</figure>
			</div>
		</div>
  );
}

export default Benefits;
