import { Link } from "gatsby";
import React, { useState } from "react";
import imgCindy from "../images/Cindy.png";
import imgGlenn from "../images/Glenn.png";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";

function Experts() {

	const [isCindyExpanded, setCindyToggle] = useState(false);
	const [isGlennExpanded, setGlennToggle] = useState(false);
  const experts = [
    {
      id: 1,
      name: 'Cindy Parsons',
      title: 'Registered Psychologist',
      img: imgCindy,
			toggle: isCindyExpanded,
      bio: 'A registered AHPRA psychologist with extensive experience working across the educational sector from primary school students through to university graduates. Cindy has spent many years working with individuals experiencing trauma, abuse and neglect, in addition to supporting organisations and their employees during times of critical need.'
    }, {
      id: 2,
      name: 'Glenn McCluskey',
      title: 'Human Resources Professional',
      img: imgGlenn,
			toggle: isGlennExpanded,
      bio: 'A Human Resources professional with many years experience working with, and supporting, people suffering with the challenges of mental health. As an accredited Mental Health First Aid Australia Instructor, Glenn has applied his knowledge and firsthand experience in the development of the Mental Health First Aid Kits to support a person’s health, wellbeing and the opportunity for a healthy mind. '
    }
  ];

	function handleClick(id) {
		if (id == 1) {
			setCindyToggle(!isCindyExpanded);
		} else {
			setGlennToggle(!isGlennExpanded);
		}
	}

  return (<div className="lg:flex lg:items-center space-y-12 lg:space-x-12 xl:space-x-24">
    <div className="lg:w-1/2 space-y-8">
      <h2>The experts behind the Mental Health Support Kit</h2>
      <p>The Mental Health Support Kits have been created by two experienced Mental Health professionals who are passionate about the essential principals of self-care and the prevention of mental health illnesses.</p>
    </div>
    <ul className="lg:w-1/2 space-y-6 lg:space-y-12">
      {
        experts.map((expert) => (<li className="flex flex-wrap" key={expert.id}>
					<div className="flex items-center ">
	          <figure className="w-24 w-24 lg:w-32 lg:w-32 xl:w-56 xl:h-56 flex-none mr-4 lg:mr-8">
							<img src={expert.img} alt={expert.name} />
						</figure>
	          <div>
	            <p className="text-xl font-medium text-blue xl:text-2xl">{expert.name}</p>
	            <p className="text-base xl:text-lg">{expert.title}<br/>
							<button onClick={() => handleClick(expert.id)} className="border-b-2 border-yellow hover:border-teal" >Biography {expert.toggle ? <FaChevronUp className="inline text-base text-blue" /> : <FaChevronDown className="inline text-base" /> }</button></p>
	          </div>
					</div>
					{expert.toggle ? <p className="text-base mt-6 lg:text-sm xl:text-base">{expert.bio}</p> : '' }
        </li>))
      }
    </ul>
  </div>);
}

export default Experts;
