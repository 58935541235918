import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from '../components/section';

function IndexPage() {

  return (<Layout>
    <SEO keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]} title="Home"/>

    <Section name="intro" bgcolor="bg-gradient-to-t from-teal-light"/>
    <Section name="why" bgcolor="bg-white"/>
    <Section name="kit" bgcolor="bg-teal-light"/>
    <Section name="benefits" bgcolor="bg-white"/>
    <Section name="authors" bgcolor="bg-yellow-light"/>
    <Section name="services" bgcolor="bg-white"/>
    <Section name="contact" bgcolor="bg-teal-light"/>

  </Layout>);
}

export default IndexPage;
