import {Link} from "gatsby";
import React from "react";
import Card from "../components/card";

function Services() {
  return (<div className="lg:flex lg:items-center space-y-12 lg:space-x-12 xl:space-x-24">
    <div>
      <h2 className="pb-8 lg:text-center">Company Employee Support Services Available</h2>
      <div className="md:space-y-0 grid gap-x-4 md:grid-cols-2 xl:gap-x-16 gap-y-16">
        <Card title="Mental Health First Aid Training" description="Mental Health First Aid Training is available to support company employees such as managers, supervisors and all levels of company team. The training is delivered by an accredited Mental Health First Aid Australia training instructor." bgcolor="bg-yellow-light"/>
        <Card title="Corporate Mental Health Educational Workshops" description="Virtual or in-person workshops are available to provide the basic understanding of Mental Health and provide some helpful tips to support a person who may be exhibiting early signs of experiencing some mental health challenges." bgcolor="bg-yellow-light"/>
        <Card title="Employee Support Programs" description="Support material and tools to support employees working remotely and experiencing the current pressures. Assistance tools to help balance the competing demands of work and family lives during these challenging times." bgcolor="bg-yellow-light"/>
        <Card title="Wellbeing & Mental Health Employee Online Education" description="A series of on-line educational lectures are available to support employees in the areas of Wellbeing and Mental Health. The lectures series covers topics such as resilience, maintaining good mental health, tools to support wellbeing and tips to maintain a healthy work / life balance." bgcolor="bg-yellow-light"/>
      </div>
    </div>
  </div>);
}

export default Services;
