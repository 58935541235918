import PropTypes from "prop-types";
import React from "react";
import Experts from "../sections/experts";
import Benefits from "../sections/benefits";
import Included from "../sections/included";
import Why from "../sections/why";
import Intro from "../sections/intro";
import Contact from "../sections/contact";
import Services from "../sections/services";

function Section(props) {

  let section;
  if (props.name == 'authors') {
    section = <Experts/>;
  } else if (props.name == 'benefits') {
    section = <Benefits/>;
  } else if (props.name == 'kit') {
    section = <Included/>;
  } else if (props.name == 'why') {
    section = <Why/>;
  } else if (props.name == 'intro') {
    section = <Intro/>;
  } else if (props.name == 'contact') {
    section = <Contact/>;
  } else if (props.name == 'services') {
    section = <Services/>;
  }

  return (<section id={`${props.name}`} className={`min-h-screen flex items-center py-32 lg:py-40 xl:py-48 ${props.bgcolor}`}>
    <div className="container m-auto">
      {section}
    </div>
  </section>);
}
Section.propTypes = {
  name: PropTypes.node.isRequired,
  bgcolor: PropTypes.string
};

export default Section;
