import React from "react";
import imgHero from "../images/Intro.svg";

function Intro() {
  return (<div className="space-y-8 lg:space-y-0 md:flex md:flex-col md:items-center md:space-x-12 lg:flex-row xl:space-x-24">
    <div className="m-auto md:w-2/3 lg:w-1/2">
      <figure><img className="m-auto" src={imgHero} alt="Man laying down using eye shades with a stress ball and pocket guide nearby." /></figure>
    </div>
    <div className="space-y-8 md:w-2/3 lg:w-1/2">
      <h1 className="text-center lg:text-left">Mental Health Support Kits</h1>
      <p className="text-center lg:text-left">An accessible, expertly developed toolkit designed to support the mental health and wellbeing of the students, employees or colleagues in your organisation.</p>
    </div>
  </div>);
}

export default Intro;
