import React from "react";
import girlMeditating from "../images/Why.png";

function Why() {
  return (<div className="lg:flex lg:items-center space-y-8 lg:space-y-0 lg:space-x-12 xl:space-x-24">
    <div className="space-y-8 lg:w-1/2">
      <h2>Why a Mental Health Support Kit?</h2>
      <p>Good mental health practices help people feel in control of their emotions, increases concentration and cognitive functioning, and promotes positive interactions with those around them. These benefits result in a happier person and a more positive state of mind – supporting that person to perform well at work, in their studies, and in family and other social relationships.</p>
      <p>The Mental Health Support Kit provides your people with the tips and tools to help them understand and form good mental health practices. The kits are an important self-care and empowerment tool that business leaders, managers, principals and teachers can use to show their employees and students that they care about them and their mental wellbeing – now and into the future. </p>
    </div>
    <div className="md:w-2/3 lg:w-1/2 md:m-auto">
      <figure className="relative">
				<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" className="absolute z-10">
  				<circle cx="50" cy="50" r="50" fill="#F1F9F7" />
				</svg>
				<img className="z-20 relative" src={girlMeditating} alt="Illustration of female meditating." />
				<figcaption className="text-xl xl:text-2xl text-center leading-tight text-blue mt-8 lg:mt-4">Help your organisation reduce fear, anxiety and stress.</figcaption>
			</figure>
    </div>
  </div>);
}

export default Why;
