import React from "react";
import {useFormik, ErrorMessage} from 'formik';

function encode(data) {
  return Object.keys(data).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&')
}

const Contact = () => {
  const [submitState, setSubmitState] = React.useState(false)

  const validate = values => {
    const errors = {};
    !values.name
      ? errors.name = 'Required'
      : null;
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    !values.message
      ? errors.message = 'Required'
      : null;
    return errors;
  };

  const ContactForm = () => {
    const [state, setState] = React.useState({})

    const formik = useFormik({
      initialValues: {
        name: '',
        email: '',
        message: ''
      },
      validate,
      onSubmit: values => {
        fetch('/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: encode({
            'form-name': "Contact",
            ...values
          })
        }).then(setSubmitState(true)).catch((error) => alert(error));
      }
    });

    return (<> < h2 className = "text-center" > Make an enquiry</h2> < p className = "lg:text-center lg:w-3/4 m-auto" > Would you like to obtain further information or enquire about an order? Fill out the form below and one of our team will get in touch.</p>
  <form name="Contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" onSubmit={formik.handleSubmit}>
    <input type="hidden" name="form-name" value="Contact"/>
    <div className="hidden">
      <label>Don’t fill this out if you’re human:
        <input name="bot-field"/></label>
    </div>
    <div className="space-y-4 lg:flex lg:space-x-8 xl:space-x-12">
      <div className="lg:w-1/2 space-y-4">
        <div className="xl:space-y-2">
          <label htmlFor="name">Name</label>
          <input id="name" type="text" name="name" placeholder="Enter your full name" onChange={formik.handleChange} value={formik.values.name}/> {
            formik.errors.name && formik.touched.name
              ? (<div className="text-red-600">{formik.errors.name}</div>)
              : null
          }
        </div>
        <div className="xl:space-y-2">
          <label htmlFor="organisation">Organisation's name</label>
          <input id="organisation" type="text" name="organisaion" placeholder="Enter the name of the organisation you represent" onChange={formik.handleChange} value={formik.values.organisation}/>
        </div>
        <div className="xl:space-y-2">
          <label htmlFor="email">Email address</label>
          <input id="email" type="email" name="email" placeholder="your@email.com" onChange={formik.handleChange} value={formik.values.email}/> {
            formik.errors.email && formik.touched.email
              ? (<div className="text-red-600">{formik.errors.email}</div>)
              : null
          }
        </div>
        <div className="xl:space-y-2">
          <label htmlFor="phone">Phone number</label>
          <input id="phone" type="tel" name="phone" placeholder="+61 000 000 000" onChange={formik.handleChange} value={formik.values.phone}/>
        </div>
      </div>
      <div className="lg:w-1/2 space-y-4">
        <div>
          <label htmlFor="message">Enquiry</label>
          <textarea id="message" name="message" placeholder="Enter your enquiry details" className="h-48 lg:h-64 xl:h-84" onChange={formik.handleChange} defaultValue={formik.values.message}/> {
            formik.errors.message && formik.touched.message
              ? (<div className="text-red-600">{formik.errors.message}</div>)
              : null
          }
        </div>
        <div>
          <legend className="w-full text-base font-medium mr-4 lg:flex lg:items-center xl:text-lg space-y-2 lg:space-y-0">Preferred contact
            <div className="lg:ml-6  flex items-center"><input id="preferrredEmail" type="radio" name="preferred" className="mr-2 w-8 h-8 lg:w-6 lg:h-6" checked="checked" onChange={formik.handleChange}/>
              <label htmlFor="preferrredEmail" className="m-0">Email</label>
            </div>
            <div className="lg:ml-6 flex items-center"><input id="preferredPhone" type="radio" name="preferred" className="mr-2 w-8 h-8 lg:w-6 lg:h-6" onChange={formik.handleChange}/>
              <label htmlFor="preferredPhone" className="m-0">Phone</label>
            </div>
          </legend>
        </div>
      </div>
    </div>
    <div className="text-center mt-12">
      <button type="submit" className="bg-yellow border-0 py-3 px-6 inline-block m-auto rounded-lg font-medium text-lg text-blue hover:bg-teal hover:text-white hover:cursor-pointer">Enquire</button>
    </div>
  </form>
</>)
  }

  const ContactFormSubmitted = () => {
    return (<> < h2 className = "text-center" > Thank you</h2> < p className = "lg:text-center lg:w-3/4 m-auto" > Your enquiry has been submitted, a team member will get in touch you with you shortly.</p>
  <div className="space-y-4 lg:flex lg:space-x-8 xl:space-x-12">
    <button type="button" onClick={() => setSubmitState(false)} className="bg-yellow border-0 py-3 px-6 inline-block m-auto rounded-lg font-medium text-lg text-blue hover:bg-teal hover:text-white hover:cursor-pointer">Reset</button>
  </div>
</>)
  }

  return (<div className="space-y-12">
    {
      submitState
        ? <ContactFormSubmitted/>
        : <ContactForm/>
    }
  </div>);
}

export default Contact;
