import PropTypes from "prop-types";
import React from "react";
import Button from "../components/button"
function Card(props) {
  props.bgcolor ? props.bgcolor : `bg-white`
  return (<div className={`text-center bg-white rounded-lg shadow-lg pt-8 pb-10 px-6 md:py-10 md:px-8 xl:py-12 xl:px-16 h-auto flex flex-col ${props.bgcolor}`}>
    <h3 className="text-center text-xl md:text-xl xl:text-2xl pb-0 mb-6">{props.title}</h3>
    <div className="text-base flex-grow">{props.description}</div>
    <div className="mt-8">
      <Button link="/#contact" label="Make an enquiry"/>
    </div>
  </div>);
}

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

export default Card;
