import React from "react";
import Card from "../components/card";
import kitBall from "../images/Kit_Ball+Plugs@2x.png";
import kitBag from "../images/Kit_Bag@2x.png";
import kitBottle from "../images/Kit_Bottle@2x.png";
import kitEyeMask from "../images/Kit_Eyemask@2x.png";
import kitGuide from "../images/Kit_Guide@2x.png";
import pocketGuide from "../images/Pocket-Guide.png";
import brandedKit from "../images/Kit-Branded.png";
import Slider from "react-slick";

function Included() {

  const slideshowSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
    arrows: false
  };

  const slideshow = [
    {
      id: 1,
      imgSrc: kitBall,
      caption: 'Stress ball and ear plugs'
    }, {
      id: 2,
      imgSrc: kitBag,
      caption: 'Carry bag'
    }, {
      id: 3,
      imgSrc: kitBottle,
      caption: 'Compact water bottle'
    }, {
      id: 4,
      imgSrc: kitEyeMask,
      caption: 'Block out eye mask'
    }, {
      id: 5,
      imgSrc: kitGuide,
      caption: 'Activities pocket guide'
    }
  ];

  return (<div className="space-y-16 xl:space-y-24">
    <div className="space-y-8 xl:space-y-16">
      <div class="text-center space-y-8">
        <h2 class="text-center">What’s included in the Mental Health Support Kit</h2>
        <p>The Mental Health Support Kits are available in several packages. Each kit includes the Mental Health Support Kit Pocket Guide. The packages have been designed to meet the needs of most organisations. In addition, we offer the option of customisation to include a list of additional items to cater for specific business or educational needs or circumstances.</p>
        <p>
          Package details and pricing are available upon request including the option of company branding.</p>
        <p className="font-medium text-base">Pricing is available upon request and is dependent on elements such as quantity and branding options.</p>
      </div>
      <hr className="border-blue-light"/>
      <div>
        <div className="flex flex-col lg:flex-row lg:space-x-24">
          <div className="lg:w-1/2 space-y-8">
            <h3>Pocket guide</h3>
            <p>The Mental Health Support Kit Pocket Guide is the foundation on which the kits have been developed. The Pocket Guide is an easy to carry (credit card sized) tool that provides a reminder and tips for managing the essential elements required for good mental health.</p>
            <p>The Pocket Guide links to the tools included in the Support Kit to support healthy self-care and wellbeing.</p>
          </div>
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <figure className="lg:mx-12"><img className="m-auto" src={pocketGuide} alt="Pocket guide."/></figure>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row lg:flex-row-reverse lg:items-center">
        <div className="lg:w-1/2 space-y-8">
          <h3>Company Branding</h3>
          <p>The kits and the contents have been designed for the inclusion of Company / Institution branding where requested. The Company logo provides a reassurance and confirmation of the support and care provided for employees, team members and/or students. Logos included in the Mental Health Support Kits also send a very positive branding message to strategic clients as recognition of their continued support
          </p>
          <p className="text-base">Note – minimum volumes and additional costs may apply to the branding options.</p>
        </div>
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <figure className="lg:mx-12"><img className="m-auto" src={brandedKit} alt="Example branded kit."/></figure>
        </div>
      </div>
      <div className="lg:flex lg:items-center lg:space-x-12 xl:space-x-24">
        <div className="lg:w-1/2 space-y-8">
          <h3>Example Kit</h3>
          <p>A Mental Health Support Kit could include a pocket guide, stress ball, standard eye shades, ear plugs, carry bag and drink bottle.&nbsp;
            <a href="/#contact">Enquire</a>&nbsp;for a full range of options.</p>
        </div>
        <div className="lg:w-1/2 lg:block">
          <Slider {...slideshowSettings}>
            {
              slideshow.map(slide => <figure key={slide.id}>
                <img className="m-auto" src={slide.imgSrc} alt={slide.caption}/>
                <figcaption className="text-center">{slide.caption}</figcaption>
              </figure>)
            }
          </Slider>
        </div>
      </div>
    </div>

    <div>
      <h4 className="pb-8">Optional inclusions for the Mental Health Support Kits</h4>
      <div className="md:space-y-0 grid gap-x-4 md:grid-cols-2 xl:gap-x-16 gap-y-16">
        <Card title="Maintaining Good Mental Health" description="A variety of helpful tips and tools are available to assist a person maintain good mental health and wellbeing. Activity planning guides and templates are available for inclusion in the kits or in support of a corporate mental health program for employees."/>
        <Card title="Mental Health Resilience Tools" description="Guides, tools and activities to help build a person’s mental health resilience are available for inclusion in the kits or in support of a corporate mental health program for employees."/>
        <Card title="Self-Care Booklet / Guide" description="A self-care booklet is available which provides tools to assist a person in their personal, work, and social environments. The booklet provides some assessment tools and tips and tools to assist in maintaining good mental health and wellbeing."/>
        <Card title="Supportive Merchandise" description="A selection of supportive merchandise is available for inclusion in the kits. A range of products is available including, but not limited to, coffee cups, mental health activity pads, stress balls, caps, and drink bottles. Company branding is also available at an additional cost."/>
      </div>
    </div>

  </div>);
}

export default Included;
